<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Saldo</th>
                  <th colspan="3">Organización - Elección</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="election in elections">
                  <tr :key="election.id" class="font-weight-black">
                    <td>
                      {{ election.id }}
                    </td>
                    <td>
                      {{ election.balance }}
                    </td>
                    <td>
                      {{ election.organization.name }} - {{ election.name }}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr
                    v-for="(token, index) in election.tokens"
                    :key="`${index}+${token.token}`"
                  >
                    <td></td>
                    <td>{{ token.total }}</td>
                    <td>{{ token.provider }}</td>
                    <td>{{ token.token }}</td>
                    <td>
                      <v-btn
                        class="mx-auto my-2"
                        width="40%"
                        @click.prevent="accept(token)"
                        ><v-icon color="green">mdi-check</v-icon></v-btn
                      >
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import elections from "@/services/zero_zone/elections";
import payments from "@/services/zero_zone/payments";

export default {
  name: "ZeroZoneElectionlist",

  data: () => ({
    elections: []
  }),
  mounted() {
    let self = this;
    elections.get().then(response => {
      self.elections = response.data;
    });
  },
  methods: {
    accept(payment) {
      payments.accept(payment).then(() => {
        window.location.reload();
      });
    }
  }
};
</script>
