<template>
  <div class="">
    <v-card class="mx-auto mt-8" flat>
      <v-card-title>
        <h2 class="red--text">Elecciones</h2>
      </v-card-title>
      <v-card-text class="pb-8">
        <listElections></listElections>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import listElections from "@/components/zero_zone/elections/List.vue";
export default {
  name: "ZeroZoneElections",
  components: {
    listElections
  }
};
</script>
