import axios from "axios";
import authHeader from "./../authHeader";

const API = process.env.VUE_APP_API_URL + "/zero_zone/accept_payments";

export default {
  accept(payment) {
    return axios.put(API + '/' + payment.id, {}, {
      headers: authHeader()
    });
  }
};